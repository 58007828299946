html {
    margin: 0;
    scroll-behavior: smooth;
}
    
body {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    resize: none;
    background-color: white;
}

.no-scroll {
    overflow: hidden;
}